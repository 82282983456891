<template>
  <transition name="fade">
    <div
      :class="['box__scratch bg--white', {'show' : scratchVisibility}]"
    >
      <img
        v-if="configuration.prescription === 'yes'"
        :src="src"
        :alt="translation.prescriptionText"
        class="image"
      />
      <div class="no-prescription">
        <h2 class="box__heading">
          {{ translation.scratchTitleText }}
        </h2>
        <div class="box__container">
          <div class="left__side">
            <h3 class="heading">
              {{ translation.scratchSubtitleText }}
            </h3>
            <p class="description">
              {{ translation.scratchDescText }}
            </p>
            <h3 class="heading">
              {{ translation.scratchSubtitle2Text }}
            </h3>
            <p class="description">
              {{ translation.scratchDesc2Text }}
            </p>
            <h3 class="heading">
              {{ translation.scratchSubtitle3Text }}
            </h3>
            <p class="description">
              {{ translation.scratchDesc3Text }}
            </p>
            <p>
              {{ translation.scratchDesc4Text }}
            </p>
          </div>
          <img
            :src="moduleDir + 'views/img/eyes-scratch.png'"
            :alt="translation.scratchImageDescText"
            class="right__side"
          >
        </div>
        <h2 class="box__heading box__heading--sm">
          {{ translation.scratchTitle2Text }}
        </h2>
        <ul class="images_list">
          <li
            v-for="(item, index) in translation.scratchListText" :key="'list' + index"
            class="item"
          >
            <div class="item__text">
              <span class="list__no">
                {{ index + 1 }}
              </span>
              <p>
                {{ item.text }}
              </p>
            </div>
            <img
              v-if="index < 5"
              :src="moduleDir + 'views/img/scratch/'+(index + 1)+'.svg'"
              alt=""
            >
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      translation: window.translation,
      moduleDir: window.moduleDir
    }
  },
  computed: {
    ...mapState(['configuration', 'scratchVisibility'])
  }
}
</script>
<style lang="scss" scoped>
  .image {
    align-items: flex-start;
  }
</style>
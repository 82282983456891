import { render, staticRenderFns } from "./Box.vue?vue&type=template&id=521c6be3&"
import script from "./Box.vue?vue&type=script&lang=js&"
export * from "./Box.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jassmin/Projekty/e-okularnicy/prestashop/modules/glasses_configurator/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('521c6be3')) {
      api.createRecord('521c6be3', component.options)
    } else {
      api.reload('521c6be3', component.options)
    }
    module.hot.accept("./Box.vue?vue&type=template&id=521c6be3&", function () {
      api.rerender('521c6be3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "views/vue/Box.vue"
export default component.exports
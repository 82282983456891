<template>
  <ul :class="['box__list', {'box__tiles' : areTiles}]">
    <li class="item" v-for="(item, index) in items" :key="'item'+index">
      <button
        :class="['item__btn', { 'tile' : areTiles }, { 'selected' : configuration[name] === item[valueName]}]"
        @click.prevent = "save(name, item[valueName])"
      >
        <span class="item__text">
          {{ item.title }}
          <small v-if="item.description">
            {{ item.description }}
          </small>
        </span>
        <span
          class="icon icon__caret-right"
          :aria-label="translation.goText"
        />
      </button>
    </li>
  </ul>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    areTiles: {
      type: Boolean,
      required: false,
      default: false
    },
    noRedirect: {
      type: Boolean,
      required: false,
      default: false
    },
    valueName: {
      type: String,
      required: false,
      default: 'title'
    }
  },
  data () {
    return {
      translation: window.translation
    }
  },
  computed: {
    ...mapState(['configuration', 'view']),
    redirect () {
      return !this.noRedirect
    }
  },
  methods: {
    ...mapMutations(['setConfiguration', 'setView', 'setAntyreflexItems']),
    save (name, value) {
      this.setConfiguration({ name, value })
      if (this.redirect) {
        this.setView(this.view + 1)
      }
    }
  },
  mounted () {
    if (this.name === 'antyreflex') {
      this.$store.commit('setAntyreflexItems', this.items)
    }
  }
}
</script>

<style>

</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _vm.visibility
      ? _c(
          "div",
          { class: ["box__right"] },
          [
            _c("div", { staticClass: "box__header heading--offset" }, [
              _c("button", {
                staticClass: "back icon__arrow-left2",
                attrs: { "aria-label": _vm.translation.backText },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.back($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "close icon__times",
                attrs: { "aria-label": _vm.translation.closeText },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.close($event)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm._t("content")
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
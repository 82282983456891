import Vue from 'vue'
import Vuelidate from 'vuelidate'
import store from '../store/index'
import { mapState, mapMutations } from 'vuex'
import axios from 'axios';

import Box from '@/Box.vue'
import List from '@/List.vue'
import Scratch from '@/Scratch.vue'
import ConfiguratorForm from '@/ConfiguratorForm.vue'

const configuratorView = document.getElementById('toggler')

if (configuratorView) {

  Vue.use(Vuelidate)

  new Vue({
    el: '#app',
    store,
    components: {
      Box,
      List,
      Scratch,
      ConfiguratorForm
    },
    data () {
      return {
        window,
        attributes: [],
        features: [],
        labels: {
          antyreflex: 'Antyreflex',
          lensKind: 'Rodzaj soczewek'
        },
        error: null
      }
    },
    computed: {
      ...mapState(['view', 'configuration', 'visibility', 'scratchVisibility', 'antyreflexItems']),
      antyreflexObj () {
        const label = this.labels.antyreflex
        return this.features.filter(attribute => attribute.key === label)[0]
      },
      antyreflexQuery () {
        const antyreflexValue = this.configuration.antyreflex
        const value = this.antyreflexItems.find(item => antyreflexValue === item.value).title
        return (antyreflexValue !== null && this.antyreflexObj) ? this.antyreflexObj.key + '-' + value : null
      },
      lensKindObj () {
        const label = this.labels.lensKind
        return this.features.filter(attribute => attribute.key === label)[0]
      },
      lensKindQuery () {
        return (this.configuration.lensKind && this.lensKindObj) ? this.lensKindObj.key + '-' + this.configuration.lensKind : null
      },
      searchURL () {
        const data = [
          this.antyreflexQuery,
          this.lensKindQuery
        ]

        const query = data.filter(singleData => singleData !== null).map(singleData => singleData).join('/');
        return window.homeCategoryURL + '?q=' + query
      },
      validSave () {
        return (this.areLens) ? true : this.$store.getters['getAntyreflex'] !== null
      },
      productAttributeId () {
        return prestashop.product.id_product_attribute
      },
      productId () {
        return prestashop.product.id_product
      },
      productAttributes () {
        let attributes = []

        if (prestashop.product && prestashop.product.attributes) {
          Object.values(prestashop.product.attributes).forEach(item => {
            attributes.push(item)
          });
        } else {
          attributes = null
        }

        return attributes
      },
      productAttributesConvert () {
        let attributes = {}

        if (this.productAttributes) {
          this.productAttributes.forEach(item => {
            const key = item['id_attribute_group']
            attributes[key] = item.id_attribute
          })
        } else {
          attributes = null
        }

        return attributes
      },
      areLens () {
        return !!document.getElementById('toggler').dataset['are_lens']
      },
      isFrame () {
        return !!document.getElementById('toggler').dataset['is_frame']
      }
    },
    methods: {
      ...mapMutations(['toggleScratchVisibility', 'setScratchVisibility', 'setView', 'setVisibility']),
      saveAnswers () {
        this.error = null

        if (this.validSave) {
          axios.post('/module/glasses_configurator/save', {
            "id_frame": this.isFrame ? this.productId : null,
            "id_lens": this.areLens ? this.productId : null,
            "group": this.productAttributesConvert,
            "lens_type": (this.areLens) ? null : `${this.configuration.lensKind} ${this.configuration.lensType}`,
            "has_prescription": (this.configuration.prescription === 'yes') ? 1 : 0,
            "left_sphere": this.configuration.leftEyeSphere !== null ? `${this.configuration.sphereLeftSign}${parseFloat(this.configuration.leftEyeSphere)}` : null,
            "left_cylinder": this.configuration.leftEyeCylinder !== null ? `${this.configuration.cylinderLeftSign}${parseFloat(this.configuration.leftEyeCylinder)}` : null,
            "left_axis": parseFloat(this.configuration.leftEyeAxis),
            "right_sphere": this.configuration.rightEyeSphere !== null ? `${this.configuration.sphereRightSign}${parseFloat(this.configuration.rightEyeSphere)}` : null,
            "right_cylinder": this.configuration.rightEyeCylinder !== null ? `${this.configuration.cylinderRightSign}${parseFloat(this.configuration.rightEyeCylinder)}` : null,
            "right_axis": parseFloat(this.configuration.rightEyeAxis),
            "anti_glare": this.configuration.antyreflex,
            "pd": (this.configuration.pdOption == '1') ? this.configuration.valuePD : `OP ${this.configuration.valueRightPD} OL ${this.configuration.valueLeftPD}`
          }).then(() => {
            if (this.areLens) {
              this.setVisibility(false)
              window.location = prestashop.urls.current_url
            } else {
              window.location = this.searchURL
            }
          }).catch( () => {
            this.error = "Wystąpił błąd. Proszę spróbować później."
          })
        }
      },
      checkConfiguratorType () {
        if (this.areLens) {
          this.setView(3)
        }
      }
    },
    mounted() {
      this.checkConfiguratorType()
      this.setScratchVisibility(false)
      this.$nextTick(() => {
        const attributes = document.getElementsByClassName('attributes');
        const features = document.getElementsByClassName('features');

        for (let i=0; i<attributes.length; i++) {
          this.attributes.push({
            key: attributes[i].dataset.key,
            values: JSON.parse(attributes[i].dataset.values)
          })
        }

        for (let i=0; i<features.length; i++) {
          this.features.push({
            key: features[i].dataset.key,
            values: JSON.parse(features[i].dataset.values)
          })
        }
      })
    }
  })

  new Vue({
    el: '#toggler',
    store,
    methods: {
      ...mapMutations(['setVisibility'])
    }
  })

}
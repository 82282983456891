<template>
  <transition name="slide">
    <div v-if="visibility" :class="['box__right']">
      <div class="box__header heading--offset">
        <button class="back icon__arrow-left2" :aria-label="translation.backText" @click.prevent="back"></button>
        <button class="close icon__times" :aria-label="translation.closeText" @click.prevent="close"></button>
      </div>
      <slot name="content"></slot>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    areLens: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      translation: window.translation
    }
  },
  computed: {
    ...mapState(['visibility', 'view'])
  },
  methods: {
    ...mapMutations(['setVisibility', 'setView']),
    close () {
      this.setVisibility(false)
    },
    back () {
      if (this.view > 1) {
        if (this.view === 3 && this.areLens) {
          this.close()
        } else {
          this.setView(this.view - 1)
        }
      } else {
        this.close()
      }
    }
  }
}
</script>
<template>
  <form @submit.prevent="submit" novalidate :class="{'was-validated': $v.$invalid && formError}">
    <div class="item__line item__line--form">
      <h2 class="sign">
        <span class="text__main">
          {{ translation.rightEyeText }}
        </span>
        <span class="text__huge">
          {{ translation.rightText }}
        </span>
      </h2>
      <div class="form-group form-group--spacing grow basis-25">
        <div class="text__center">
          <label
            for="sphereRight"
            class="form-control-label position__relative"
          >
            {{ translation.sphereText }}
            <span
              class="field__tooltip"
              data-toggle="tooltip"
              data-placement="top"
              :title="translation.sphereTooltipText"
            >
              <span class="icon icon__question-mark" :aria-label="translation.additionalInfoText"></span>
            </span>
          </label>
        </div>
        <div class="d-flex">
          <Select2 v-model="sphereRightSign" :options="['+', '-']" :settings="{ minimumResultsForSearch: -1 }" class="select--sign" id="sphereRightSign"/>
          <input
            type="number"
            id="sphereRight"
            step="0.25"
            min="0"
            max="20"
            :class="['form-control', {'is-invalid': $v.rightEyeSphere.$invalid && formError}]"
            v-model.number="$v.rightEyeSphere.$model"
          >
        </div>
        <span class="invalid-feedback invalid-feedback--form" v-if="$v.rightEyeSphere.$invalid">
          <template v-if="!$v.rightEyeSphere.minValue">Niepoprawna wartość</template>
          <template v-else-if="!$v.rightEyeSphere.maxValue">{{ translation.errorMaxValueSphereText }}</template>
          <template v-else>{{ translation.errorRequiredText }}</template>
        </span>
      </div>
      <div class="form-group form-group--spacing grow basis-25">
        <div class="text__center">
          <label
            for="cylinderRight"
            class="form-control-label position__relative"
          >
            {{ translation.cylinderText }}
            <span
              class="field__tooltip"
              data-toggle="tooltip"
              data-placement="top"
              :title="translation.cylinderTooltipText"
            >
              <span class="icon icon__question-mark" :aria-label="translation.additionalInfoText"></span>
            </span>
          </label>
        </div>
        <div class="d-flex">
          <Select2 v-model="cylinderRightSign" :options="['+', '-']" :settings="{ minimumResultsForSearch: -1 }" class="select--sign" id="cylinderRightSign"/>
          <input
            type="number"
            id="cylinderRight"
            step="0.25"
            min="0"
            max="4"
            :class="['form-control', {'is-invalid': $v.rightEyeCylinder.$invalid && formError}]"
            v-model.number="$v.rightEyeCylinder.$model"
          >
        </div>
        <span class="invalid-feedback invalid-feedback--form" v-if="$v.rightEyeCylinder.$invalid">
          <template v-if="!$v.rightEyeCylinder.minValue">Niepoprawna wartość</template>
          <template v-else-if="!$v.rightEyeCylinder.maxValue">{{ translation.errorMaxValueCylinderText }}</template>
          <template v-else>{{ translation.errorRequiredText }}</template>
        </span>
      </div>
      <div class="form-group form-group--spacing grow basis-25">
        <div class="text__center">
          <label
            for="axisRight"
            class="form-control-label position__relative"
          >
            {{ translation.axisText }}
            <span
              class="field__tooltip"
              data-toggle="tooltip"
              data-placement="top"
              :title="translation.axisTooltipText"
            >
              <span class="icon icon__question-mark" :aria-label="translation.additionalInfoText"></span>
            </span>
          </label>
        </div>
        <input
          type="number"
          id="axisRight"
          step="1"
          min="0"
          max="180"
          :class="['form-control no-arrows', {'is-invalid': $v.rightEyeAxis.$invalid && formError}]"
          v-model.number="$v.rightEyeAxis.$model"
        >
        <span class="invalid-feedback invalid-feedback--form" v-if="$v.rightEyeAxis.$invalid">
          <template v-if="!$v.rightEyeAxis.minValue">{{ translation.errorMinValueText }}</template>
          <template v-else-if="!$v.rightEyeAxis.maxValue">{{ translation.errorMaxValueText }}</template>
          <template v-else>{{ translation.errorRequiredText }}</template>
        </span>
      </div>
    </div>
    <div class="item__line item__line--form">
      <h2 class="sign">
        <span class="text__main">
          {{ translation.leftEyeText }}
        </span>
        <span class="text__huge">
          {{ translation.leftText }}
        </span>
      </h2>
      <div class="form-group form-group--spacing grow basis-25">
        <div class="text__center">
          <label
            for="sphereLeft"
            class="form-control-label position__relative"
          >
            {{ translation.sphereText }}
          </label>
        </div>
        <div class="d-flex">
          <Select2 v-model="sphereLeftSign" :options="['+', '-']" :settings="{ minimumResultsForSearch: -1 }" class="select--sign" id="sphereLeftSign"/>
          <input
            type="number"
            id="sphereLeft"
            step="0.25"
            min="0"
            max="20"
            :class="['form-control', {'is-invalid': $v.leftEyeSphere.$invalid && formError}]"
            v-model.number="$v.leftEyeSphere.$model"
          >
        </div>
        <span class="invalid-feedback invalid-feedback--form" v-if="$v.leftEyeSphere.$invalid">
          <template v-if="!$v.leftEyeSphere.minValue">Niepoprawna wartość</template>
          <template v-else-if="!$v.leftEyeSphere.maxValue">{{ translation.errorMaxValueSphereText }}</template>
          <template v-else>{{ translation.errorRequiredText }}</template>
        </span>
      </div>
      <div class="form-group form-group--spacing grow basis-25">
        <div class="text__center">
          <label
            for="cylinderLeft"
            class="form-control-label position__relative"
          >
            {{ translation.cylinderText }}
          </label>
        </div>
        <div class="d-flex">
          <Select2 v-model="cylinderLeftSign" :options="['+', '-']" :settings="{ minimumResultsForSearch: -1 }" class="select--sign" id="cylinderLeftSign"/>
          <input
            type="number"
            id="cylinderLeft"
            step="0.25"
            min="0"
            max="4"
            :class="['form-control', {'is-invalid': $v.leftEyeCylinder.$invalid && formError}]"
            v-model.number="$v.leftEyeCylinder.$model"
          >
        </div>
        <span class="invalid-feedback invalid-feedback--form" v-if="$v.leftEyeCylinder.$invalid">
          <template v-if="!$v.leftEyeCylinder.minValue">Niepoprawna wartość</template>
          <template v-else-if="!$v.leftEyeCylinder.maxValue">{{ translation.errorMaxValueCylinderText }}</template>
          <template v-else>{{ translation.errorRequiredText }}</template>
        </span>
      </div>
      <div class="form-group form-group--spacing grow basis-25">
        <div class="text__center">
          <label
            for="axisLeft"
            class="form-control-label position__relative"
          >
            {{ translation.axisText }}
          </label>
        </div>
        <input
          type="number"
          id="axisLeft"
          step="1"
          min="0"
          max="180"
          :class="['form-control no-arrows', {'is-invalid': $v.leftEyeAxis.$invalid && formError}]"
          v-model.number="$v.leftEyeAxis.$model"
        >
        <span class="invalid-feedback invalid-feedback--form" v-if="$v.leftEyeAxis.$invalid">
          <template v-if="!$v.leftEyeAxis.minValue">{{ translation.errorMinValueText }}</template>
          <template v-else-if="!$v.leftEyeAxis.maxValue">{{ translation.errorMaxValueText }}</template>
          <template v-else>{{ translation.errorRequiredText }}</template>
        </span>
      </div>
    </div>
    <div>
      <h2 class="form__heading">
        {{ translation.pupillaryDistanceText }}
      </h2>
      <p class="form__description">
        {{ (isPrescription) ? translation.pupillaryDistanceDescPrescriptionText : translation.pupillaryDistanceDescText }}
      </p>
      <div class="form-group form__options">
        <label :class="['form__option form__label semi-bold', {'text--dark-pink': pdOption == 1}]">
          <span class="custom-radio">
            <input
              type="radio"
              name="pd_options"
              value="1"
              v-model="pdOption"
              @input="valueRightPD = null; valueLeftPD = null"
            >
            <span :class="{'border--red': $v.pdOption.$invalid && formError}"></span>
          </span>
          <span :class="['custom-radio__text', {'is-invalid': $v.pdOption.$invalid && formError}]">{{ (isPrescription) ? translation.pdFirstOptionPrescriptionText : translation.pdFirstOptionText }}</span>
        </label>
        <label :class="['form__option form__label semi-bold', {'text--dark-pink': pdOption == 2}]">
          <span class="custom-radio">
            <input
              type="radio"
              name="pd_options"
              value="2"
              v-model="pdOption"
              @input="valuePD = null"
            >
            <span :class="{'border--red': $v.pdOption.$invalid && formError}"></span>
          </span>
          <span :class="['custom-radio__text', {'is-invalid': $v.pdOption.$invalid && formError}]">{{ (isPrescription) ? translation.pdSecondOptionPrescriptionText : translation.pdSecondOptionText }}</span>
        </label>
      </div>
      <div class="form__values">
        <div
          v-if="pdOption == 1"
          class="form-group form__value"
        >
          <label
            for="valuePD"
            class="form__label"
          >
            {{ translation.pdValue }}
          </label>
          <input
            type="number"
            id="valuePD"
            min="30"
            max="80"
            step="0.5"
            :class="['form-control no-arrows', {'is-invalid': $v.valuePD.$invalid && formError}]"
            :disabled="pdOption != 1"
            v-model.number="$v.valuePD.$model"
          >
          <span class="invalid-feedback invalid-feedback--form" v-if="$v.valuePD.$invalid">
            <template v-if="!$v.valuePD.minValue">{{ translation.errorMinValueSinglePDText }}</template>
            <template v-else-if="!$v.valuePD.maxValue">{{ translation.errorMaxValueSinglePDText }}</template>
            <template v-else>{{ translation.errorRequiredText }}</template>
          </span>
        </div>
        <div
          v-if="pdOption == 2"
          class="form__value"
        >
          <fieldset style="max-width: 250px;">
            <legend class="form__label form__legend mr-0">{{ translation.pdValue }}</legend>
            <div class="form-inline">
              <label
                for="valueRightPD"
                class="form__label mr-3"
              >
                {{ translation.rightEyeText }}
              </label>
              <input
                type="number"
                id="valueRightPD"
                min="15"
                max="40"
                step="0.5"
                :class="['form-control no-arrows', {'is-invalid': $v.valueRightPD.$invalid && formError}]"
                :disabled="pdOption != 2"
                v-model.number="$v.valueRightPD.$model"
              >
              <span class="invalid-feedback invalid-feedback--form invalid-feedback--two-pds" v-if="$v.valueRightPD.$invalid">
                <template v-if="!$v.valueRightPD.minValue">{{ translation.errorMinValuePDText }}</template>
                <template v-else-if="!$v.valueRightPD.maxValue">{{ translation.errorMaxValuePDText }}</template>
                <template v-else>{{ translation.errorRequiredText }}</template>
              </span>
            </div>
            <div class="form-inline">
              <label
                for="valueLeftPD"
                class="form__label mr-3"
              >
                {{ translation.leftEyeText }}
              </label>
              <input
                type="number"
                id="valueLeftPD"
                min="15"
                max="40"
                step="0.5"
                :class="['form-control no-arrows', {'is-invalid': $v.valueLeftPD.$invalid && formError}]"
                :disabled="pdOption != 2"
                v-model.number="$v.valueLeftPD.$model"
              >
              <span class="invalid-feedback invalid-feedback--form invalid-feedback--two-pds" v-if="$v.valueLeftPD.$invalid">
                <template v-if="!$v.valueLeftPD.minValue">{{ translation.errorMinValuePDText }}</template>
                <template v-else-if="!$v.valueLeftPD.maxValue">{{ translation.errorMaxValuePDText }}</template>
                <template v-else>{{ translation.errorRequiredText }}</template>
              </span>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="form__footer">
        <div>
          <span class="form__additional-text" v-if="!isPrescription">
            {{ translation.agreementAdditionalText }}
          </span>
          <label class="form__label">
            <span class="custom-checkbox">
              <input
                name="agreement"
                type="checkbox"
                v-model="agreement"
                :class="['form-control', {'is-invalid': $v.agreement.$invalid && formError}]"
              >
              <span class="form__checkbox"><i class="material-icons rtl-no-flip checkbox-checked"></i></span>
              <span class="form__checkbox__text">
                {{ (isPrescription) ? translation.agreementPrescriptionText : translation.agreementText }}
              </span>
            </span>
          </label>
        </div>
        <button class="btn btn-primary">
          {{ (areLens) ? translation.saveText : translation.nextText }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { required, requiredIf, sameAs, maxValue, minValue } from 'vuelidate/lib/validators'
import Select2 from 'v-select2-component'

export default {
  props: {
    areLens: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Select2
  },
  data () {
    return {
      translation: window.translation,
      formError: false
    }
  },
  validations: {
    rightEyeSphere: {
      minValue: minValue(0),
      maxValue: maxValue(20)
    },
    rightEyeCylinder: {
      required: requiredIf(function() { return !!this.rightEyeAxis || this.rightEyeAxis === 0 }),
      minValue: minValue(0),
      maxValue: maxValue(4)
    },
    rightEyeAxis: {
      required: requiredIf(function() { return !!this.rightEyeCylinder || this.rightEyeCylinder === 0 }),
      minValue: minValue(0),
      maxValue: maxValue(180)
    },
    leftEyeSphere: {
      minValue: minValue(0),
      maxValue: maxValue(20)
    },
    leftEyeCylinder: {
      required: requiredIf(function() { return !!this.leftEyeAxis || this.leftEyeAxis === 0 }),
      minValue: minValue(0),
      maxValue: maxValue(4)
    },
    leftEyeAxis: {
      required: requiredIf(function() { return !!this.leftEyeCylinder || this.leftEyeCylinder === 0 }),
      minValue: minValue(0),
      maxValue: maxValue(180)
    },
    pdOption: {
      required
    },
    valuePD: {
      required: requiredIf(function (nestedModel) {
        return this.pdOption == '1'
      }),
      minValue: minValue(30),
      maxValue: maxValue(80)
    },
    valueRightPD: {
      required: requiredIf(function (nestedModel) {
        return this.pdOption == '2'
      }),
      minValue: minValue(15),
      maxValue: maxValue(40)
    },
    valueLeftPD: {
      required: requiredIf(function (nestedModel) {
        return this.pdOption == '2'
      }),
      minValue: minValue(15),
      maxValue: maxValue(40)
    },
    agreement: {
      required,
      sameAs: sameAs(() => true)
    }
  },
  computed: {
    ...mapState(['view', 'configuration']),
    ...mapGetters([
      'getRightEyeSphere',
      'getRightEyeCylinder',
      'getRightEyeAxis',
      'getLeftEyeSphere',
      'getLeftEyeCylinder',
      'getLeftEyeAxis',
      'getPdOption',
      'getValuePD',
      'getValueRightPD',
      'getValueLeftPD',
      'getAgreement',
      'getSphereRightSign',
      'getCylinderRightSign',
      'getCylinderLeftSign',
      'getSphereLeftSign'
    ]),
    isPrescription () {
      return this.configuration.prescription === 'yes'
    },
    rightEyeSphere: {
      get () {
        return this.getRightEyeSphere
      },
      set (value) {
        this.setConfiguration({
          name: 'rightEyeSphere',
          value
        })
      }
    },
    rightEyeCylinder: {
      get () {
        return this.getRightEyeCylinder
      },
      set (value) {
        this.setConfiguration({
          name: 'rightEyeCylinder',
          value
        })
      }
    },
    rightEyeAxis: {
      get () {
        return this.getRightEyeAxis
      },
      set (value) {
        this.setConfiguration({
          name: 'rightEyeAxis',
          value
        })
      }
    },
    leftEyeSphere: {
      get () {
        return this.getLeftEyeSphere
      },
      set (value) {
        this.setConfiguration({
          name: 'leftEyeSphere',
          value
        })
      }
    },
    leftEyeCylinder: {
      get () {
        return this.getLeftEyeCylinder
      },
      set (value) {
        this.setConfiguration({
          name: 'leftEyeCylinder',
          value
        })
      }
    },
    leftEyeAxis: {
      get () {
        return this.getLeftEyeAxis
      },
      set (value) {
        this.setConfiguration({
          name: 'leftEyeAxis',
          value
        })
      }
    },
    sphereRightSign: {
      get () {
        return this.getSphereRightSign
      },
      set (value) {
        this.setConfiguration({
          name: 'sphereRightSign',
          value
        })
      }
    },
    cylinderRightSign: {
      get () {
        return this.getCylinderRightSign
      },
      set (value) {
        this.setConfiguration({
          name: 'cylinderRightSign',
          value
        })
      }
    },
    sphereLeftSign: {
      get () {
        return this.getSphereLeftSign
      },
      set (value) {
        this.setConfiguration({
          name: 'sphereLeftSign',
          value
        })
      }
    },
    cylinderLeftSign: {
      get () {
        return this.getCylinderLeftSign
      },
      set (value) {
        this.setConfiguration({
          name: 'cylinderLeftSign',
          value
        })
      }
    },
    pdOption: {
      get () {
        return this.getPdOption
      },
      set (value) {
        this.setConfiguration({
          name: 'pdOption',
          value
        })
      }
    },
    valuePD: {
      get () {
        return this.getValuePD
      },
      set (value) {
        this.setConfiguration({
          name: 'valuePD',
          value
        })
      }
    },
    valueRightPD: {
      get () {
        return this.getValueRightPD
      },
      set (value) {
        this.setConfiguration({
          name: 'valueRightPD',
          value
        })
      }
    },
    valueLeftPD: {
      get () {
        return this.getValueLeftPD
      },
      set (value) {
        this.setConfiguration({
          name: 'valueLeftPD',
          value
        })
      }
    },
    agreement: {
      get () {
        return this.getAgreement
      },
      set (value) {
        this.setConfiguration({
          name: 'agreement',
          value
        })
      }
    }
  },
  methods: {
    ...mapMutations(['setConfiguration', 'setView']),
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.formError = true
      } else {
        this.formError = false
        if (this.areLens) {
          this.$emit('save')
        }
        else {
          this.setView(this.view + 1)
        }
      }
    }
  },
  mounted() {
    if (jQuery) {
      jQuery('[data-toggle="tooltip"]').tooltip()
    }
  }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { class: ["box__scratch bg--white", { show: _vm.scratchVisibility }] },
      [
        _vm.configuration.prescription === "yes"
          ? _c("img", {
              staticClass: "image",
              attrs: { src: _vm.src, alt: _vm.translation.prescriptionText }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "no-prescription" }, [
          _c("h2", { staticClass: "box__heading" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.translation.scratchTitleText) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "left__side" }, [
              _c("h3", { staticClass: "heading" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.translation.scratchSubtitleText) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.translation.scratchDescText) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "heading" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.translation.scratchSubtitle2Text) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.translation.scratchDesc2Text) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "heading" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.translation.scratchSubtitle3Text) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.translation.scratchDesc3Text) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.translation.scratchDesc4Text) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "right__side",
              attrs: {
                src: _vm.moduleDir + "views/img/eyes-scratch.png",
                alt: _vm.translation.scratchImageDescText
              }
            })
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "box__heading box__heading--sm" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.translation.scratchTitle2Text) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "images_list" },
            _vm._l(_vm.translation.scratchListText, function(item, index) {
              return _c("li", { key: "list" + index, staticClass: "item" }, [
                _c("div", { staticClass: "item__text" }, [
                  _c("span", { staticClass: "list__no" }, [
                    _vm._v(
                      "\n              " + _vm._s(index + 1) + "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n              " + _vm._s(item.text) + "\n            "
                    )
                  ])
                ]),
                _vm._v(" "),
                index < 5
                  ? _c("img", {
                      attrs: {
                        src:
                          _vm.moduleDir +
                          "views/img/scratch/" +
                          (index + 1) +
                          ".svg",
                        alt: ""
                      }
                    })
                  : _vm._e()
              ])
            }),
            0
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      class: { "was-validated": _vm.$v.$invalid && _vm.formError },
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "item__line item__line--form" }, [
        _c("h2", { staticClass: "sign" }, [
          _c("span", { staticClass: "text__main" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.translation.rightEyeText) + "\n      "
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text__huge" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.translation.rightText) + "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group form-group--spacing grow basis-25" },
          [
            _c("div", { staticClass: "text__center" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label position__relative",
                  attrs: { for: "sphereRight" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.translation.sphereText) +
                      "\n          "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "field__tooltip",
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: _vm.translation.sphereTooltipText
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "icon icon__question-mark",
                        attrs: {
                          "aria-label": _vm.translation.additionalInfoText
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("Select2", {
                  staticClass: "select--sign",
                  attrs: {
                    options: ["+", "-"],
                    settings: { minimumResultsForSearch: -1 },
                    id: "sphereRightSign"
                  },
                  model: {
                    value: _vm.sphereRightSign,
                    callback: function($$v) {
                      _vm.sphereRightSign = $$v
                    },
                    expression: "sphereRightSign"
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.$v.rightEyeSphere.$model,
                      expression: "$v.rightEyeSphere.$model",
                      modifiers: { number: true }
                    }
                  ],
                  class: [
                    "form-control",
                    {
                      "is-invalid":
                        _vm.$v.rightEyeSphere.$invalid && _vm.formError
                    }
                  ],
                  attrs: {
                    type: "number",
                    id: "sphereRight",
                    step: "0.25",
                    min: "0",
                    max: "20"
                  },
                  domProps: { value: _vm.$v.rightEyeSphere.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.rightEyeSphere,
                        "$model",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.$v.rightEyeSphere.$invalid
              ? _c(
                  "span",
                  { staticClass: "invalid-feedback invalid-feedback--form" },
                  [
                    !_vm.$v.rightEyeSphere.minValue
                      ? [_vm._v("Niepoprawna wartość")]
                      : !_vm.$v.rightEyeSphere.maxValue
                      ? [
                          _vm._v(
                            _vm._s(_vm.translation.errorMaxValueSphereText)
                          )
                        ]
                      : [_vm._v(_vm._s(_vm.translation.errorRequiredText))]
                  ],
                  2
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group form-group--spacing grow basis-25" },
          [
            _c("div", { staticClass: "text__center" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label position__relative",
                  attrs: { for: "cylinderRight" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.translation.cylinderText) +
                      "\n          "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "field__tooltip",
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: _vm.translation.cylinderTooltipText
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "icon icon__question-mark",
                        attrs: {
                          "aria-label": _vm.translation.additionalInfoText
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("Select2", {
                  staticClass: "select--sign",
                  attrs: {
                    options: ["+", "-"],
                    settings: { minimumResultsForSearch: -1 },
                    id: "cylinderRightSign"
                  },
                  model: {
                    value: _vm.cylinderRightSign,
                    callback: function($$v) {
                      _vm.cylinderRightSign = $$v
                    },
                    expression: "cylinderRightSign"
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.$v.rightEyeCylinder.$model,
                      expression: "$v.rightEyeCylinder.$model",
                      modifiers: { number: true }
                    }
                  ],
                  class: [
                    "form-control",
                    {
                      "is-invalid":
                        _vm.$v.rightEyeCylinder.$invalid && _vm.formError
                    }
                  ],
                  attrs: {
                    type: "number",
                    id: "cylinderRight",
                    step: "0.25",
                    min: "0",
                    max: "4"
                  },
                  domProps: { value: _vm.$v.rightEyeCylinder.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.rightEyeCylinder,
                        "$model",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.$v.rightEyeCylinder.$invalid
              ? _c(
                  "span",
                  { staticClass: "invalid-feedback invalid-feedback--form" },
                  [
                    !_vm.$v.rightEyeCylinder.minValue
                      ? [_vm._v("Niepoprawna wartość")]
                      : !_vm.$v.rightEyeCylinder.maxValue
                      ? [
                          _vm._v(
                            _vm._s(_vm.translation.errorMaxValueCylinderText)
                          )
                        ]
                      : [_vm._v(_vm._s(_vm.translation.errorRequiredText))]
                  ],
                  2
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group form-group--spacing grow basis-25" },
          [
            _c("div", { staticClass: "text__center" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label position__relative",
                  attrs: { for: "axisRight" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.translation.axisText) +
                      "\n          "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "field__tooltip",
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: _vm.translation.axisTooltipText
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "icon icon__question-mark",
                        attrs: {
                          "aria-label": _vm.translation.additionalInfoText
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.$v.rightEyeAxis.$model,
                  expression: "$v.rightEyeAxis.$model",
                  modifiers: { number: true }
                }
              ],
              class: [
                "form-control no-arrows",
                { "is-invalid": _vm.$v.rightEyeAxis.$invalid && _vm.formError }
              ],
              attrs: {
                type: "number",
                id: "axisRight",
                step: "1",
                min: "0",
                max: "180"
              },
              domProps: { value: _vm.$v.rightEyeAxis.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.$v.rightEyeAxis,
                    "$model",
                    _vm._n($event.target.value)
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.rightEyeAxis.$invalid
              ? _c(
                  "span",
                  { staticClass: "invalid-feedback invalid-feedback--form" },
                  [
                    !_vm.$v.rightEyeAxis.minValue
                      ? [_vm._v(_vm._s(_vm.translation.errorMinValueText))]
                      : !_vm.$v.rightEyeAxis.maxValue
                      ? [_vm._v(_vm._s(_vm.translation.errorMaxValueText))]
                      : [_vm._v(_vm._s(_vm.translation.errorRequiredText))]
                  ],
                  2
                )
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item__line item__line--form" }, [
        _c("h2", { staticClass: "sign" }, [
          _c("span", { staticClass: "text__main" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.translation.leftEyeText) + "\n      "
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text__huge" }, [
            _vm._v("\n        " + _vm._s(_vm.translation.leftText) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group form-group--spacing grow basis-25" },
          [
            _c("div", { staticClass: "text__center" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label position__relative",
                  attrs: { for: "sphereLeft" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.translation.sphereText) +
                      "\n        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("Select2", {
                  staticClass: "select--sign",
                  attrs: {
                    options: ["+", "-"],
                    settings: { minimumResultsForSearch: -1 },
                    id: "sphereLeftSign"
                  },
                  model: {
                    value: _vm.sphereLeftSign,
                    callback: function($$v) {
                      _vm.sphereLeftSign = $$v
                    },
                    expression: "sphereLeftSign"
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.$v.leftEyeSphere.$model,
                      expression: "$v.leftEyeSphere.$model",
                      modifiers: { number: true }
                    }
                  ],
                  class: [
                    "form-control",
                    {
                      "is-invalid":
                        _vm.$v.leftEyeSphere.$invalid && _vm.formError
                    }
                  ],
                  attrs: {
                    type: "number",
                    id: "sphereLeft",
                    step: "0.25",
                    min: "0",
                    max: "20"
                  },
                  domProps: { value: _vm.$v.leftEyeSphere.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.leftEyeSphere,
                        "$model",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.$v.leftEyeSphere.$invalid
              ? _c(
                  "span",
                  { staticClass: "invalid-feedback invalid-feedback--form" },
                  [
                    !_vm.$v.leftEyeSphere.minValue
                      ? [_vm._v("Niepoprawna wartość")]
                      : !_vm.$v.leftEyeSphere.maxValue
                      ? [
                          _vm._v(
                            _vm._s(_vm.translation.errorMaxValueSphereText)
                          )
                        ]
                      : [_vm._v(_vm._s(_vm.translation.errorRequiredText))]
                  ],
                  2
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group form-group--spacing grow basis-25" },
          [
            _c("div", { staticClass: "text__center" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label position__relative",
                  attrs: { for: "cylinderLeft" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.translation.cylinderText) +
                      "\n        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("Select2", {
                  staticClass: "select--sign",
                  attrs: {
                    options: ["+", "-"],
                    settings: { minimumResultsForSearch: -1 },
                    id: "cylinderLeftSign"
                  },
                  model: {
                    value: _vm.cylinderLeftSign,
                    callback: function($$v) {
                      _vm.cylinderLeftSign = $$v
                    },
                    expression: "cylinderLeftSign"
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.$v.leftEyeCylinder.$model,
                      expression: "$v.leftEyeCylinder.$model",
                      modifiers: { number: true }
                    }
                  ],
                  class: [
                    "form-control",
                    {
                      "is-invalid":
                        _vm.$v.leftEyeCylinder.$invalid && _vm.formError
                    }
                  ],
                  attrs: {
                    type: "number",
                    id: "cylinderLeft",
                    step: "0.25",
                    min: "0",
                    max: "4"
                  },
                  domProps: { value: _vm.$v.leftEyeCylinder.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.leftEyeCylinder,
                        "$model",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.$v.leftEyeCylinder.$invalid
              ? _c(
                  "span",
                  { staticClass: "invalid-feedback invalid-feedback--form" },
                  [
                    !_vm.$v.leftEyeCylinder.minValue
                      ? [_vm._v("Niepoprawna wartość")]
                      : !_vm.$v.leftEyeCylinder.maxValue
                      ? [
                          _vm._v(
                            _vm._s(_vm.translation.errorMaxValueCylinderText)
                          )
                        ]
                      : [_vm._v(_vm._s(_vm.translation.errorRequiredText))]
                  ],
                  2
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group form-group--spacing grow basis-25" },
          [
            _c("div", { staticClass: "text__center" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label position__relative",
                  attrs: { for: "axisLeft" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.translation.axisText) +
                      "\n        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.$v.leftEyeAxis.$model,
                  expression: "$v.leftEyeAxis.$model",
                  modifiers: { number: true }
                }
              ],
              class: [
                "form-control no-arrows",
                { "is-invalid": _vm.$v.leftEyeAxis.$invalid && _vm.formError }
              ],
              attrs: {
                type: "number",
                id: "axisLeft",
                step: "1",
                min: "0",
                max: "180"
              },
              domProps: { value: _vm.$v.leftEyeAxis.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.$v.leftEyeAxis,
                    "$model",
                    _vm._n($event.target.value)
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.leftEyeAxis.$invalid
              ? _c(
                  "span",
                  { staticClass: "invalid-feedback invalid-feedback--form" },
                  [
                    !_vm.$v.leftEyeAxis.minValue
                      ? [_vm._v(_vm._s(_vm.translation.errorMinValueText))]
                      : !_vm.$v.leftEyeAxis.maxValue
                      ? [_vm._v(_vm._s(_vm.translation.errorMaxValueText))]
                      : [_vm._v(_vm._s(_vm.translation.errorRequiredText))]
                  ],
                  2
                )
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h2", { staticClass: "form__heading" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.translation.pupillaryDistanceText) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form__description" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.isPrescription
                  ? _vm.translation.pupillaryDistanceDescPrescriptionText
                  : _vm.translation.pupillaryDistanceDescText
              ) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group form__options" }, [
          _c(
            "label",
            {
              class: [
                "form__option form__label semi-bold",
                { "text--dark-pink": _vm.pdOption == 1 }
              ]
            },
            [
              _c("span", { staticClass: "custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pdOption,
                      expression: "pdOption"
                    }
                  ],
                  attrs: { type: "radio", name: "pd_options", value: "1" },
                  domProps: { checked: _vm._q(_vm.pdOption, "1") },
                  on: {
                    input: function($event) {
                      _vm.valueRightPD = null
                      _vm.valueLeftPD = null
                    },
                    change: function($event) {
                      _vm.pdOption = "1"
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  class: {
                    "border--red": _vm.$v.pdOption.$invalid && _vm.formError
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: [
                    "custom-radio__text",
                    { "is-invalid": _vm.$v.pdOption.$invalid && _vm.formError }
                  ]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isPrescription
                        ? _vm.translation.pdFirstOptionPrescriptionText
                        : _vm.translation.pdFirstOptionText
                    )
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "label",
            {
              class: [
                "form__option form__label semi-bold",
                { "text--dark-pink": _vm.pdOption == 2 }
              ]
            },
            [
              _c("span", { staticClass: "custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pdOption,
                      expression: "pdOption"
                    }
                  ],
                  attrs: { type: "radio", name: "pd_options", value: "2" },
                  domProps: { checked: _vm._q(_vm.pdOption, "2") },
                  on: {
                    input: function($event) {
                      _vm.valuePD = null
                    },
                    change: function($event) {
                      _vm.pdOption = "2"
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  class: {
                    "border--red": _vm.$v.pdOption.$invalid && _vm.formError
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: [
                    "custom-radio__text",
                    { "is-invalid": _vm.$v.pdOption.$invalid && _vm.formError }
                  ]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isPrescription
                        ? _vm.translation.pdSecondOptionPrescriptionText
                        : _vm.translation.pdSecondOptionText
                    )
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form__values" }, [
          _vm.pdOption == 1
            ? _c("div", { staticClass: "form-group form__value" }, [
                _c(
                  "label",
                  { staticClass: "form__label", attrs: { for: "valuePD" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.translation.pdValue) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.$v.valuePD.$model,
                      expression: "$v.valuePD.$model",
                      modifiers: { number: true }
                    }
                  ],
                  class: [
                    "form-control no-arrows",
                    { "is-invalid": _vm.$v.valuePD.$invalid && _vm.formError }
                  ],
                  attrs: {
                    type: "number",
                    id: "valuePD",
                    min: "30",
                    max: "80",
                    step: "0.5",
                    disabled: _vm.pdOption != 1
                  },
                  domProps: { value: _vm.$v.valuePD.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.valuePD,
                        "$model",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.valuePD.$invalid
                  ? _c(
                      "span",
                      {
                        staticClass: "invalid-feedback invalid-feedback--form"
                      },
                      [
                        !_vm.$v.valuePD.minValue
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm.translation.errorMinValueSinglePDText
                                )
                              )
                            ]
                          : !_vm.$v.valuePD.maxValue
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm.translation.errorMaxValueSinglePDText
                                )
                              )
                            ]
                          : [_vm._v(_vm._s(_vm.translation.errorRequiredText))]
                      ],
                      2
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pdOption == 2
            ? _c("div", { staticClass: "form__value" }, [
                _c("fieldset", { staticStyle: { "max-width": "250px" } }, [
                  _c(
                    "legend",
                    { staticClass: "form__label form__legend mr-0" },
                    [_vm._v(_vm._s(_vm.translation.pdValue))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-inline" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form__label mr-3",
                        attrs: { for: "valueRightPD" }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.translation.rightEyeText) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.$v.valueRightPD.$model,
                          expression: "$v.valueRightPD.$model",
                          modifiers: { number: true }
                        }
                      ],
                      class: [
                        "form-control no-arrows",
                        {
                          "is-invalid":
                            _vm.$v.valueRightPD.$invalid && _vm.formError
                        }
                      ],
                      attrs: {
                        type: "number",
                        id: "valueRightPD",
                        min: "15",
                        max: "40",
                        step: "0.5",
                        disabled: _vm.pdOption != 2
                      },
                      domProps: { value: _vm.$v.valueRightPD.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.valueRightPD,
                            "$model",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.valueRightPD.$invalid
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "invalid-feedback invalid-feedback--form invalid-feedback--two-pds"
                          },
                          [
                            !_vm.$v.valueRightPD.minValue
                              ? [
                                  _vm._v(
                                    _vm._s(_vm.translation.errorMinValuePDText)
                                  )
                                ]
                              : !_vm.$v.valueRightPD.maxValue
                              ? [
                                  _vm._v(
                                    _vm._s(_vm.translation.errorMaxValuePDText)
                                  )
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(_vm.translation.errorRequiredText)
                                  )
                                ]
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-inline" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form__label mr-3",
                        attrs: { for: "valueLeftPD" }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.translation.leftEyeText) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.$v.valueLeftPD.$model,
                          expression: "$v.valueLeftPD.$model",
                          modifiers: { number: true }
                        }
                      ],
                      class: [
                        "form-control no-arrows",
                        {
                          "is-invalid":
                            _vm.$v.valueLeftPD.$invalid && _vm.formError
                        }
                      ],
                      attrs: {
                        type: "number",
                        id: "valueLeftPD",
                        min: "15",
                        max: "40",
                        step: "0.5",
                        disabled: _vm.pdOption != 2
                      },
                      domProps: { value: _vm.$v.valueLeftPD.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.valueLeftPD,
                            "$model",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.valueLeftPD.$invalid
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "invalid-feedback invalid-feedback--form invalid-feedback--two-pds"
                          },
                          [
                            !_vm.$v.valueLeftPD.minValue
                              ? [
                                  _vm._v(
                                    _vm._s(_vm.translation.errorMinValuePDText)
                                  )
                                ]
                              : !_vm.$v.valueLeftPD.maxValue
                              ? [
                                  _vm._v(
                                    _vm._s(_vm.translation.errorMaxValuePDText)
                                  )
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(_vm.translation.errorRequiredText)
                                  )
                                ]
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form__footer" }, [
          _c("div", [
            !_vm.isPrescription
              ? _c("span", { staticClass: "form__additional-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.translation.agreementAdditionalText) +
                      "\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("label", { staticClass: "form__label" }, [
              _c("span", { staticClass: "custom-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.agreement,
                      expression: "agreement"
                    }
                  ],
                  class: [
                    "form-control",
                    { "is-invalid": _vm.$v.agreement.$invalid && _vm.formError }
                  ],
                  attrs: { name: "agreement", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.agreement)
                      ? _vm._i(_vm.agreement, null) > -1
                      : _vm.agreement
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.agreement,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.agreement = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.agreement = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.agreement = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("span", { staticClass: "form__checkbox__text" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.isPrescription
                          ? _vm.translation.agreementPrescriptionText
                          : _vm.translation.agreementText
                      ) +
                      "\n            "
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "btn btn-primary" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.areLens
                    ? _vm.translation.saveText
                    : _vm.translation.nextText
                ) +
                "\n      "
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form__checkbox" }, [
      _c("i", { staticClass: "material-icons rtl-no-flip checkbox-checked" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import 'babel-polyfill'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    visibility: false,
    view: 1,
    scratchVisibility: true,
    configuration: {
      lensType: null,
      lensKind: null,
      prescription: null,
      rightEyeSphere: null,
      rightEyeCylinder: null,
      rightEyeAxis: null,
      leftEyeSphere: null,
      leftEyeCylinder: null,
      leftEyeAxis: null,
      sphereRightSign: '+',
      cylinderRightSign: '+',
      sphereLeftSign: '+',
      cylinderLeftSign: '+',
      pdOption: null,
      valuePD: null,
      valueRightPD: null,
      valueLeftPD: null,
      agreement: null,
      antyreflex: null
    },
    antyreflexItems: null
  },
  getters: {
    getRightEyeSphere: state => {
      return state.configuration.rightEyeSphere
    },
    getRightEyeCylinder: state => {
      return state.configuration.rightEyeCylinder
    },
    getRightEyeAxis: state => {
      return state.configuration.rightEyeAxis
    },
    getLeftEyeSphere: state => {
      return state.configuration.leftEyeSphere
    },
    getLeftEyeCylinder: state => {
      return state.configuration.leftEyeCylinder
    },
    getLeftEyeAxis: state => {
      return state.configuration.leftEyeAxis
    },
    getPdOption: state => {
      return state.configuration.pdOption
    },
    getValuePD: state => {
      return state.configuration.valuePD
    },
    getValueRightPD: state => {
      return state.configuration.valueRightPD
    },
    getValueLeftPD: state => {
      return state.configuration.valueLeftPD
    },
    getAgreement: state => {
      return state.configuration.agreement
    },
    getAntyreflex: state => {
      return state.configuration.antyreflex
    },
    getSphereRightSign: state => {
      return state.configuration.sphereRightSign
    },
    getCylinderRightSign: state => {
      return state.configuration.cylinderRightSign
    },
    getSphereLeftSign: state => {
      return state.configuration.sphereLeftSign
    },
    getCylinderLeftSign: state => {
      return state.configuration.cylinderLeftSign
    }
  },
  mutations: {
    setConfiguration: (state, { name, value }) => {
      state.configuration[name] = value
    },
    setView: (state, value) => {
      state.view = value
    },
    setVisibility: (state, value) => {
      state.visibility = value
    },
    toggleScratchVisibility: (state) => {
      state.scratchVisibility = !state.scratchVisibility
    },
    setScratchVisibility: (state, value) => {
      state.scratchVisibility = value
    },
    setAntyreflexItems: (state, value) => {
      state.antyreflexItems = value
    }
  }
})
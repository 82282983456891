var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { class: ["box__list", { box__tiles: _vm.areTiles }] },
    _vm._l(_vm.items, function(item, index) {
      return _c("li", { key: "item" + index, staticClass: "item" }, [
        _c(
          "button",
          {
            class: [
              "item__btn",
              { tile: _vm.areTiles },
              { selected: _vm.configuration[_vm.name] === item[_vm.valueName] }
            ],
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.save(_vm.name, item[_vm.valueName])
              }
            }
          },
          [
            _c("span", { staticClass: "item__text" }, [
              _vm._v("\n        " + _vm._s(item.title) + "\n        "),
              item.description
                ? _c("small", [
                    _vm._v(
                      "\n          " + _vm._s(item.description) + "\n        "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "icon icon__caret-right",
              attrs: { "aria-label": _vm.translation.goText }
            })
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }